import React, { useState } from 'react';
import AxiosController from '../../api/apiController';
import { formatCurrency } from '../../utils/FormatCurrency';
import { Modal, Tabs, Button } from 'antd';
import PremiumForm from '../premium/PremiumForm';
import ProvisionForm from '../provision/ProvisionForm';
import { useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';

const { TabPane } = Tabs;

const AuctionBidAdd = ({ auctionId, detailCar }) => {
  // const formatCurrency = (value) => {
  //   return new Intl.NumberFormat('tr-TR').format(value) + ' TL';
  // };
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const is_premium = useSelector((state) => state.auth?.user?.is_premium) || false;
  const is_provizyon = useSelector((state) => state.auth?.user?.is_provizyon) || false;
  const [isModalDetailVisible, setIsModalDetailVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalPremiumFormVisible, setIsModalPremiumFormVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalDetailVisible(true);
  // };

  const handleOk = () => {
    setIsModalDetailVisible(false);
  };

  const handleCancel = () => {
    setIsModalDetailVisible(false);
  };
  const handleBid = async () => {
    try {
      const response = await AxiosController.post('/v1/auction/bid/', {
        auction_id: auctionId,
        amount: detailCar.min_increment,
      });
    } catch (error) {
      console.error('Error placing bid:', error);
    }
  };
  const checkPrivileges = () => {
    setIsModalDetailVisible(true);

  }
  const checkPremForm = () => {
    setIsModalPremiumFormVisible(true)
  }
  const checkProvisionForm = () => {
    setIsModalVisible(true)
  }

  const handlesetIsModalPremiumFormVisible = () => {
    setIsModalPremiumFormVisible(false)
  }
  return (
    <>
      {is_premium || is_provizyon ?
        <button
          className="bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] lg:px-[48px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm flex justify-center items-center"
          onClick={handleBid}
        >
          <span className="text-[#09090B] font-semibold">
            {formatCurrency(detailCar.min_increment)} Arttır
          </span>
        </button>
        :
        <div>
          {!isAuthenticated ? 
            <button
            className="bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] lg:px-[48px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm flex justify-center items-center"
          >
            <span className="text-[#09090B] font-semibold">
              Teklif Verebilmek İçin Giriş Yapın
            </span>
          </button>
          :
          <button
          onClick={checkPrivileges}
          className="bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] lg:px-[48px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm flex justify-center items-center"
        >
          <span className="text-[#09090B] font-semibold">
            Teklif Verebilmek İçin
          </span>
        </button>
          }
         

        </div>
      }
      <Modal
        title={<span>Teklif verebilmek için yöntem seçiniz</span>}
        open={isModalDetailVisible}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
        className="custom-modal"
      >
        <div className='w-full flex flex-row gap-2  py-4 px-4'>
          <button onClick={checkPremForm} className='bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] lg:px-[48px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full font-medium text-sm flex justify-center items-center'>Premium üye ol</button>
          <button onClick={checkProvisionForm} className='bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] lg:px-[48px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full font-medium text-sm flex justify-center items-center'>Provizyon Yükle</button>
        </div>
      </Modal>

      <PremiumForm isModalPremiumFormVisible={isModalPremiumFormVisible} setIsModalPremiumFormVisible={handlesetIsModalPremiumFormVisible} />
    </>

  );
};

export default AuctionBidAdd;
