import React, { useEffect, useState } from "react";
import { Steps, Button, Modal, Tooltip, Form, Input, Select, Checkbox } from "antd";
import InputMask from "react-input-mask";
import AxiosController, { baseUrl, imageBaseUrl } from "../../api/apiController";
import { formatCurrency } from "../../utils/FormatCurrency";
import { userSuccess } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";


const PremiumTab = () => {
  const dispatch = useDispatch();
  const is_premium = useSelector((state) => state.auth.user.is_premium);
  const [isModalPremiumFormVisible, setIsModalPremiumFormVisible] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessagePay, setErrorMessagePay] = useState(null);
  const [statusCode, setStatusCode] = useState(null);

  const [form] = Form.useForm();

  const fetchProductInfo = async () => {
    try {
      setLoading(true);
      const response = await AxiosController.get("/v1/payment/premium-payment");
      setProductInfo(response);
    } catch (error) {
      console.error("Error fetching product info:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductInfo();
  }, []);


  const onFinish = async (values) => {

    const response = await AxiosController.post("/v1/payment/premium-payment", {
      cc_holder_name: `${values.firstName} ${values.lastName}`,
      cc_no: values.cc_no.replace(/\s+/g, ""),
      expiry_month: values.expiryDate.split("/")[0],
      expiry_year: values.expiryDate.split("/")[1],
      cvv: values.cvv,
      name: values.firstName, // Ad
      surname: values.lastName, // Soyad
    });
    if (response.status == "success") {
      setFormValues(response.data);
      setIsIframeVisible(true);
    }
  };


  const successComplete = async (values) => {

    const response = await AxiosController.post("/v1/payment/premium-payment/success", {},);
    // if (response.status == "success") {
    //   setFormValues(response.data);
    //   setIsIframeVisible(true);
    // }
    dispatch(userSuccess({
      user: response.data,
    }));
  };

  useEffect(() => {
    const form = document.getElementById("paybull-form");
    if (form && isIframeVisible) {
      form.submit();
    }
  }, [formValues, isIframeVisible]);


  const renderForm = () => {
    const formElements = Object.keys(formValues).map((key) => (
      <input key={key} type="hidden" name={key} value={formValues[key]} />
    ));

    return (
      <form
        id="paybull-form"
        method="POST"
        action={`${baseUrl}v1/payment/proxy/paybull`}
        target="paybull-iframe"
      >
        {formElements}
        <button type="submit" style={{ display: "none" }}></button>
      </form>
    );
  };

  const handleIframeLoad = (e) => {
    const iframe = e.target;

    // iFrame elemanının var olup olmadığını kontrol et
    if (!iframe) {
      console.error("iFrame kaynağı bulunamadı!");
      return;
    }

    // iFrame'in içeriği tamamen yüklendi mi kontrol et
    setTimeout(() => {
      try {
        if (!iframe.contentWindow || !iframe.contentWindow.location) {
          return;
        }

        const iframeUrl = iframe.contentWindow.location.href;

        const urlParams = new URLSearchParams(new URL(iframeUrl).search);

        const statusCode = urlParams.get("status_code");
        if (statusCode) {
          setStatusCode(decodeURIComponent(statusCode));
          if (decodeURIComponent(statusCode) == 100) {
            successComplete();
          }
        }
        const errorMessage = urlParams.get("status_description");

        if (errorMessage) {
          setErrorMessage(decodeURIComponent(errorMessage));
        }
        const errorMessagePay = urlParams.get("payment_reason_code_detail");
        if (errorMessagePay) {
          setErrorMessagePay(decodeURIComponent(errorMessagePay));
        }

        // Eğer iFrame bankaya yönlendiyse, görünmesini sağla
        if (!iframeUrl.includes("localhost")) {
          iframe.style.display = "block"; // Banka ekranı görünür olsun
        }

      } catch (err) {
        console.error("Error accessing iframe content:", err);
      }
    }, 1000); // Küçük bir gecikme ekleyerek iframe içeriğinin yüklenmesini bekle
  };

  const openPaymentModal = () => {
    setIsModalVisible(true);
  };

  const closePaymentModal = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalPremiumFormVisible(false);
  };

  const handleFormModal = () => {
    setIsModalPremiumFormVisible(true);
  };

  const handleCancelForm = () => {
    setIsIframeVisible(false)
    setIsModalPremiumFormVisible(false);
    window.location.reload();
  };

  const onCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };
  return (
    <div className="flex flex-col mt-5 lg:mt-0">
      {/* <Modal
        title="3D Secure Payment"
        open={isIframeVisible}
        footer={null}
        onCancel={() => setIsIframeVisible(false)}
        width={800}
      >
        {renderForm()}
        <iframe
          name="paybull-iframe"
          style={{ width: "100%", height: "500px", border: "none", display: 'none' }}
          onLoad={handleIframeLoad}
        />
        {errorMessage && (
          <div style={{ marginTop: "10px", color: "red", textAlign: "center" }}>
            {errorMessage}
          </div>
        )}
      </Modal> */}
      <div className="bg-[#FFEB00] flex flex-col items-end  rounded-[16px] p-[17px] lg:p-[17px] lg:gap-x-[18px]">
        <div className="flex flex-col lg:flex-row  w-full">
          <div>
            <img src="/assets/img/blog-banner-car.png" alt="banner-car" />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col lg:ml-[32px]">
              <h6 className="font-medium text-sm lg:text-[24px] lg:leading-8 text-[09090B] lg:w-[434px]">
                Premium ile Provizyon Ödemeden Sınırsız Teklif Verebilirsiniz
              </h6>
              <span className="text-[09090B] text-sm lg:w-[434px] hidden lg:block">
                İhalenin Taksitli Hali ttihale.com
              </span>
            </div>

          </div>

        </div>
        {is_premium ?
          <button className=" min-w-max bg-[#09090B] rounded-[56px] text-[#FFEB00] text-sm font-semibold px-[12px] lg:px-[24px] py-3">
            🎉  Tebrikler! Premium Ayrıcalıkların Başladı  🚀
          </button>
          :
          <button onClick={handleFormModal} className="min-w-max bg-[#09090B] rounded-[56px] text-[#FFEB00] text-sm font-semibold px-[12px] lg:px-[24px] py-3">
            Premium Üye Ol
          </button>
        }

      </div>
      <Modal
        title="Premium Üye Ol"
        open={isModalPremiumFormVisible}
        footer={null}
        onCancel={handleCancelForm}
        className="custom-modal"
      >

        {!isIframeVisible && <Form
          form={form}
          name="paymentForm"
          layout="vertical"
          onFinish={onFinish}
          className="mx-5"
          initialValues={{
         
          }}
        >
          {/* Premium Bilgisi */}
          <div className="bg-[#FFEB00] text-[#09090B] py-2 px-5 rounded-[8px] text-lg text-center font-semibold mb-2.5">
            PREMIUM
          </div>

          {/* Ad */}
          <Form.Item
            label="Ad"
            name="firstName"
            rules={[
              { required: true, message: "Lütfen Adınızı Giriniz!" },
              { pattern: /^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]+$/, message: "Lütfen yalnızca harf giriniz!" },
            ]}
          >
            <Input placeholder="Ad" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
          </Form.Item>

          {/* Soyad */}
          <Form.Item
            label="Soyad"
            name="lastName"
            rules={[
              { required: true, message: "Lütfen Soyadınızı Giriniz!" },
              { pattern: /^[a-zA-ZçÇğĞıİöÖşŞüÜ\s]+$/, message: "Lütfen yalnızca harf giriniz!" },
            ]}
          >
            <Input placeholder="Soyad" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
          </Form.Item>

          {/* Kart Numarası */}
          <Form.Item
            label="Kart Numarası"
            name="cc_no"
            rules={[
              { required: true, message: "Lütfen Kart Numarası Giriniz" },
              // { pattern: /^\d{4} \d{4} \d{4} \d{4}$/, message: "Lütfen Geçerli Bir Kart Numarası Giriniz!" },
            ]}
          >
            <InputMask maskChar={""} mask="9999 9999 9999 9999" placeholder="Kart Numarası Giriniz">
              {(inputProps) => (
                <Input maxLength={19} {...inputProps} type="text" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
              )}
            </InputMask>
          </Form.Item>

          <div className="flex gap-4">
            {/* Son Kullanma Tarihi */}
            <Form.Item
              label="Son Kullanma Tarihi"
              name="expiryDate"
              className="-mt-1 w-1/2"
              rules={[
                { required: true, message: "Lütfen Son Kullanma Tarihini Giriniz" },
                { pattern: /^(0[1-9]|1[0-2])\/\d{2}$/, message: "Lütfen Geçerli Bir Tarih Giriniz (AA/YY)!" },
              ]}
            >
              <InputMask maskChar={""} mask="99/99" placeholder="AA/YY">
                {(inputProps) => (
                  <Input maxLength={5} {...inputProps} type="text" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
                )}
              </InputMask>
            </Form.Item>

            {/* CVV */}
            <Form.Item
              label="CVV/CVC"
              name="cvv"
              className="-mt-1 w-1/2"
              rules={[
                { required: true, message: "Lütfen CVC Giriniz!" },
                { pattern: /^\d{3}$/, message: "CVC üç basamaklı olmalıdır!" },
              ]}
            >
              <Input maxLength={3} placeholder="CVC" className="-mt-2 border border-[#E4E4E7] rounded-[8px] p-[9px]" />
            </Form.Item>
          </div>

          {/* KVKK Onayı */}
          <Form.Item name="kvkk" valuePropName="checked" className="-mt-2">
            <Checkbox onChange={onCheckboxChange}>Bilgilerimin doğruluğunu onaylıyorum.</Checkbox>
          </Form.Item>

          {/* Toplam Tutar */}
          <div className="grid grid-cols-2 items-center">
            <div className="w-full flex justify-center mb-5">
              <div className="flex flex-col">
                <span className="text-sm text-[#18181C] text-opacity-60 font-semibold">Toplam Tutar</span>
                <span className="font-semibold text-lg text-[#18181C] text-center">
                  {formatCurrency(productInfo?.product_price || 0)}
                </span>
              </div>
            </div>
            <div>
              {/* Ödeme Yap Butonu */}
              <Form.Item>
                <Tooltip title={isCheckboxChecked ? "" : "Lütfen KVKK onayı veriniz"}>
                  <button
                    type="primary"
                    htmlType="submit"
                    className="w-full py-3 rounded-[8px] font-semibold"
                    style={{
                      backgroundColor: isCheckboxChecked ? "#FFEB00" : "#f0f0f0",
                      color: isCheckboxChecked ? "#000000" : "#cccccc",
                    }}
                    disabled={!isCheckboxChecked}
                  >
                    Ödemeyi Yap
                  </button>
                </Tooltip>
              </Form.Item>
            </div>
          </div>
        </Form>}
        {isIframeVisible && <div className="mx-5 pb-5">
          {renderForm()}
          {!errorMessage && <iframe
            name="paybull-iframe"
            style={{ width: "100%", height: "500px", border: "none", }} // Başlangıçta gizli
            onLoad={handleIframeLoad}
          />}
          {statusCode == 100 ?
            <div className="w-full flex flex-col gap-4 ">
              <div className="font-semibold text-lg text-[#18181C] text-center]" style={{ marginTop: "10px", color: "green", textAlign: "center" }}>
                Ödeme Başarılı
              </div>
              <div className="">
                <button
                  onClick={() => handleCancelForm()}
                  className="w-full py-3 rounded-[8px] font-semibold"
                  style={{
                    backgroundColor: isCheckboxChecked ? "#FFEB00" : "#f0f0f0",
                    color: isCheckboxChecked ? "#000000" : "#cccccc",
                  }}
                  disabled={!isCheckboxChecked}
                >
                  Kapat
                </button>
              </div>
            </div>
            :
            <div className="w-full flex flex-col gap-4 ">
              {errorMessagePay && (
                <div className="font-semibold text-lg text-[#18181C] text-center]" style={{ marginTop: "10px", color: "red", textAlign: "center" }}>
                  {errorMessagePay}
                </div>
              )}

              {errorMessage && (
                <div className="font-semibold text-lg text-[#18181C] text-center]" style={{ marginTop: "10px", color: "red", textAlign: "center" }}>
                  {errorMessage}
                </div>
              )}
              {(errorMessage || errorMessagePay) && <div className="">
                <button
                  onClick={() => handleCancelForm()}
                  className="w-full py-3 rounded-[8px] font-semibold"
                  style={{
                    backgroundColor: isCheckboxChecked ? "#FFEB00" : "#f0f0f0",
                    color: isCheckboxChecked ? "#000000" : "#cccccc",
                  }}
                  disabled={!isCheckboxChecked}
                >
                  Tekrar Dene
                </button>
              </div>}
            </div>
          }

        </div>
        }
      </Modal>
      <div className="grid grid-cols-1 lg:grid-cols-3 mt-[48px] gap-y-[12px] lg:gap-y-[48px]">
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/car-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Sınırsız Teklif Hakkı</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/valor-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Valör ile Ödeme Esnekliği</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/support-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Özel Satış Temsilcisi Atama</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/card1-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Güvenilir Hızlı Alım Satım</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/star-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Premium’a Özel İhaleler</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/headset-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Özel Çağrı Merkezi</span>
        </div>
      </div>
      <div className="mt-[40px]">
        <div className="flex flex-col gap-y-[16px]">
          <div className="flex items-center gap-2.5">
            <img src="/assets/icons/info-icon.svg" alt="info-icon" />
            <span className="text-sm font-light text-[#09090B]">
              Premium ile daha fazla keşfedin.
            </span>
          </div>
          <div className="flex items-center gap-2.5">
            <img src="/assets/icons/info-icon.svg" alt="info-icon" />
            <span className="text-sm font-light text-[#09090B]">
              Provizyonlar gün içerisinde satın alma olmadığı takdirde iade edilmez.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumTab;
