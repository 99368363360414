import React, { useState } from 'react';
import { Modal, Button, Input, Form, Steps, Select, message, Checkbox } from 'antd';
import { PhoneOutlined, UserOutlined, MailOutlined, KeyOutlined } from '@ant-design/icons';
import AxiosController from '../../api/apiController';

const { Step } = Steps;
const { Option } = Select;

const RegisterForm = () => {
  const [isSignupOpen, setSignupOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [registrationToken, setRegistrationToken] = useState(null); // Registration token saklanacak
  const [userDocs, setUserDocs] = useState(false);
  const handleNext = () => setCurrentStep(currentStep + 1);
  const handlePrev = () => setCurrentStep(currentStep - 1);
  const handleUserDocsChange = (e) => {
    setUserDocs(e.target.checked);
  };
  const handleSignupOpen = () => {
    setSignupOpen(true);
    setCurrentStep(0); // Her signup açıldığında step sıfırlansın
  };
  const handleSignupClose = () => setSignupOpen(false);

  // 1. Adım: Kullanıcı bilgilerini API'ye gönder
  const handleUserDetailsSubmit = async (values) => {
    try {
      const response = await AxiosController.post('/v1/auth/registarion/register', values);
      setRegistrationToken(response.data.access_token);  // API'den dönen registration token
      message.success('Kullanıcı bilgileri kaydedildi!');
      handleNext();
    } catch (error) {
      message.error('Kullanıcı kaydedilemedi.');
    }
  };

  // 2. Adım: Telefon OTP doğrulama
  const handlePhoneOtpSubmit = async (values) => {
    try {
      const data = {
        registration_token: registrationToken,
        otp_code: values.otp_code, // Formdan gelen OTP değeri
      };
      const response = await AxiosController.post('/v1/auth/registarion/verify-phone', data);
      setRegistrationToken(response.data.access_token);
      message.success('Telefon doğrulandı!');
      handleNext();
    } catch (error) {
      message.error('Telefon OTP doğrulaması başarısız.');
    }
  };

  // // 3. Adım: E-posta OTP doğrulama
  // const handleEmailOtpSubmit = async (values) => {
  //   try {
  //     const data = {
  //       registration_token: registrationToken,
  //       otp_code: values.otp_code, // Formdan gelen e-posta OTP değeri
  //     };
  //     const response = await AxiosController.post('/v1/auth/registarion/verify-email', data);
  //     setRegistrationToken(response.data.access_token);
  //     message.success('E-posta doğrulandı!');
  //     handleNext();
  //   } catch (error) {
  //     message.error('E-posta OTP doğrulaması başarısız.');
  //   }
  // };

  // 4. Adım: Kayıt tamamlama ve şifre belirleme
  const handlePasswordSubmit = async (values) => {
    try {
      const data = {
        registration_token: registrationToken,
        password: values.password // Formdan gelen şifre değeri
      };
      const response = await AxiosController.post('/v1/auth/registarion/complete-registration', data);
      message.success('Kayıt tamamlandı!');
      setSignupOpen(false); // Başarılıysa modalı kapat
    } catch (error) {
      message.error('Şifre belirleme ve kayıt tamamlama işlemi başarısız.');
    }
  };

  return (
    <>
      <button onClick={handleSignupOpen} className="bg-[#FAFAFA] rounded-[56px] w-[111px] h-[48px] hover:bg-[#FFEB00]">Üye Ol</button>

      <Modal
        // title="Üye Ol"
        open={isSignupOpen}
        onCancel={handleSignupClose}
        footer={null}
                className="special-login"
      >
        {/* <Steps current={currentStep}>
          <Step title="Kullanıcı Bilgileri" />
          <Step title="Telefon Doğrulama" />
          <Step title="E-posta Doğrulama" />
          <Step title="Kayıt Tamamla ve Şifre Belirleme" />
        </Steps> */}

        <Form
          layout="vertical"
          className=''
          onFinish={
            currentStep === 0
              ? handleUserDetailsSubmit
              : currentStep === 1
                ? handlePhoneOtpSubmit
                : currentStep === 2
                  ? handlePasswordSubmit
                  : () => { }
          }
        >
          <div className="flex flex-col md:flex-row items-center">
            <div className="w-full md:w-1/2 hidden  md:flex h-full">
              <img src="/assets/img/login-ferrari.png" alt="login" height={'100%'} className="rounded-l-[16px] h-full" />
            </div>
            <div className="flex flex-col w-full md:w-1/2 px-8 py-8 md:py-4">
              <div className="">
                {/* 1. Adım: Kullanıcı Bilgileri */}
                <div >
                  {currentStep === 0 && (
                    <>
                      <div className="flex flex-col">
                        <span className="text-[#09090B] font-semibold text-[20px]">Üye Ol</span>
                        <span className="text-[#52525B] text-sm mt-[4px]">
                          Tekrar hoş geldiniz! Lütfen hesabınıza giriş yapın.
                        </span>
                      </div>
                      <Form.Item
                        name="first_name"
                        label="Adınız"
                        rules={[{ required: true, message: 'Lütfen adınızı girin!' }]}
                      >
                        <Input
                          prefix={<UserOutlined />}
                          placeholder="Adınız"
                          className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                        />
                      </Form.Item>

                      <Form.Item
                        name="last_name"
                        label="Soyadınız"
                        rules={[{ required: true, message: 'Lütfen soyadınızı girin!' }]}
                        className="-mt-2"
                      >
                        <Input
                          prefix={<UserOutlined />}
                          placeholder="Soyadınız"
                          className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                        />
                      </Form.Item>


                      <Form.Item
                        name="phone"
                        label="Telefon"
                        rules={[{ required: true, message: 'Lütfen telefon numaranızı girin!' }]}
                        className="-mt-2"
                      >
                        <Input
                          prefix={<PhoneOutlined />}
                          placeholder="Telefon"
                          className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                        />
                      </Form.Item>

                      <Form.Item
                        name="email"
                        label="E-Posta"
                        rules={[{ required: true, message: 'Lütfen e-posta adresinizi girin!' }]}
                        className="-mt-2"
                      >
                        <Input
                          prefix={<MailOutlined />}
                          placeholder="E-Posta"
                          className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1"
                        />
                      </Form.Item>
                      <Form.Item
                        name="customer_type"
                        label="Müşteri Tipi"
                        rules={[{ required: true, message: 'Lütfen müşteri tipini seçin!' }]}
                        className="-mt-2"
                      >
                        <select
                          className="rounded-[12px] border border-[#E4E4E7] p-[12px] -mt-1 w-full "
                          defaultValue="" // Varsayılan değeri boş bırakarak placeholder etkisi yaratır
                        >
                          <option value="" disabled hidden>
                            Müşteri Tipi Seçin
                          </option>
                          <option value="individual">Bireysel</option>
                          <option value="corporate">Kurumsal</option>
                        </select>
                      </Form.Item>

                      <Form.Item className="mb-[8px] -mt-2">
                        <Checkbox
                          className="custom-checkbox"
                          checked={userDocs}
                          onChange={handleUserDocsChange}
                        >
                          Üyelik Sözleşmesini onaylıyorum
                        </Checkbox>
                      </Form.Item>
                      <button
                        className="bg-[#FFEB00] rounded-[12px] text-[#09090B] flex justify-center items-center gap-2 p-[12px] font-medium"
                        type="primary"
                        htmlType="submit"
                      >
                        <img src="/assets/icons/login-icon.svg" alt="login-icon" />
                        <span>Bilgileri Kaydet</span>
                      </button>
                    </>
                  )}
                </div>
              </div>
              {/* 2. Adım: Telefon OTP Doğrulama */}
              {currentStep === 1 && (
                <>
                  <Form.Item
                    name="otp_code"
                    label="Telefon OTP"
                    rules={[{ required: true, message: 'Lütfen OTP kodunu girin!' }]}
                  >
                    <Input
                      prefix={<KeyOutlined />}
                      placeholder="OTP"
                    />
                  </Form.Item>

                  <Button type="primary" htmlType="submit">
                    OTP Doğrula
                  </Button>
                </>
              )}

              {/* 3. Adım: E-posta OTP Doğrulama */}
              {/* {currentStep === 2 && (
            <>
              <Form.Item
                name="otp_code"
                label="E-posta OTP"
                rules={[{ required: true, message: 'Lütfen e-posta OTP kodunu girin!' }]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder="E-posta OTP"
                />
              </Form.Item>

              <Button type="primary" htmlType="submit">
                E-posta OTP Doğrula
              </Button>
            </>
          )} */}

              {/* 4. Adım: Kayıt Tamamla ve Şifre Belirleme */}
              {currentStep === 2 && (
                <>
                  <Form.Item
                    name="password"
                    label="Şifre"
                    rules={[{ required: true, message: 'Lütfen şifrenizi girin!' }]}
                  >
                    <Input
                      prefix={<KeyOutlined />}
                      type="password"
                      placeholder="Şifre"
                    />
                  </Form.Item>

                  <Button type="primary" htmlType="submit">
                    Kayıt Tamamla
                  </Button>
                </>
              )}

              {currentStep > 0 && (
                <Button style={{ margin: '0 8px' }} onClick={handlePrev}>
                  Geri
                </Button>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default RegisterForm;
